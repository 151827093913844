$font-family-sans-serif: Manrope, Ubuntu, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: .85rem;

$white:    #F0F0F0;
$gray-100: #f8f9fa;
$gray-200: #E7E6EE;
$gray-300: #dee2e6;
$gray-400: #CAC9D5;
$gray-500: #606473;
$gray-600: #505463;
$gray-700: #4b4c5a;
$gray-800: #38374C;
$gray-900: #2f2f3d;
$black:    #050A1D;

$blue:       #2D7BF7;
$indigo:     #6E5C70;
$purple:     #4D43B3;
$pink:       #d63384;
$red:        #DB100F;
$orange:     #fd7e14;
$yellow:     #FED73E;
$green:      #198754;
$teal:       #20c997;
$cyan:       #43DCFF;
$dark-blue:  #576B97;
$gold:       #fdd835;

//$primary:       #BF1932;
//$primary:       #DBFC37;
$primary:       $cyan;
$secondary:     $dark-blue;
$success:       $green;
$info:          $blue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $black;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray-700,
  "dark":       $dark,
  "pink":       $pink,
  "cyan":       $cyan,
  "orange":     $orange,
  "blue":       $blue,
  "purple":     $purple,
);

$spacer: 1rem;
$border-width: 2px;

$body-bg: $dark;
$body-color: $white;
$modal-content-bg: $gray-800;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-header-padding-y: $spacer * 1.5;
$modal-header-padding-x: $spacer * 2.5;
$modal-inner-padding: $spacer * 2.5;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          800;

$font-weight-base:            $font-weight-semibold;

$enable-shadows: false;

$border-radius: 1.5rem;
$border-radius-sm: $border-radius * 0.75;
$border-radius-lg: $border-radius * 1.25;

$btn-border-width: 1px;
$btn-close-color: $black;
$btn-font-weight: $font-weight-base;
$btn-border-radius:           $border-radius / 2;
$btn-border-radius-sm:        $border-radius-sm / 2;
$btn-border-radius-lg:        $border-radius-lg / 2;
$input-border-radius:         $border-radius / 2;
$input-border-radius-sm:      $border-radius-sm / 2;
$input-border-radius-lg:      $border-radius-lg / 2;
$input-disabled-bg:           $gray-800;
$btn-padding-y: .6rem;

$global-padding: 1.25rem;

$card-bg: $gray-900;
$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;
$card-border-width: 0;
$card-border-color: $gray-600;
//$card-color: $white;
$card-box-shadow: 0 0 20px -10px rgba($black, .2);

$component-active-color: $dark;

$headings-font-weight: 700;

$accordion-bg: $gray-600;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: $dark;

$table-border-color: $dark;
$table-border-width: 2px;

$progress-height: 1.5rem;
$progress-border-radius: $border-radius / 2;
