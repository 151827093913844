.card {
  position: relative;

  .card-title {
    padding: $card-spacer-y $card-spacer-x 0;
  }
  .card-button {
    position: absolute;
    bottom: -.5rem;
    right: .5rem;
  }
}
