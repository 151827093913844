.page-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.page {
  display: flex;
  flex: 1 auto;
  width: 100%;
  flex-direction: column;
  height: 100%;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 270px);
  }
}

#header {
  color: $dark;
  z-index: 100;
  position: fixed;
  top: 0;
  background: transparent !important;
  right: 0;
  left: 0;

  .navbar-brand {
    color: $dark !important;
  }

  @include media-breakpoint-down(lg) {
    //min-height: 70px !important;
    //max-height: 70px !important;
  }

  @include media-breakpoint-up(lg) {
    left: 270px;
  }
}

#page-content {
  flex: 1 auto;
  overflow: scroll;
  padding-top: 100px;

  @include media-breakpoint-up(md) {
    padding-top: 130px;
  }
}

#footer {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 100%;
    left: 0;
    pointer-events: none;
    background: linear-gradient(to top, $body-bg, transparent);
  }
}
