@charset "UTF-8";

.slick-slider {
  padding: 0;
}

.slick-slide {
  padding: 0 15px;
}

.slick-image {
  height: 400px;
  background-position: center center;
  background-size: cover;
  display: inline-block;

  @include media-breakpoint-only(xs) {
    width: 200px;
    height: 300px;
  }

  img {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

/* Slider */
.slick-loading .slick-list
{
  background: #fff url('~slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next
{
  font-size: $font-size-lg;
  color: $primary;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  vertical-align: top;
  @include media-breakpoint-up(md) {
    margin: 0 15px;
  }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
  outline: none;
  background: transparent;
  opacity: 1;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled
{
  opacity: .25;
}

/* Dots */
.slick-dotted.slick-slider
{
  margin-bottom: 30px;
}

.slick-dots
{
  display: block;
  width: 100%;
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
  text-align: center;
}
.slick-dots li
{
  position: relative;
  display: inline-block;
  margin: 2px 10px 0;
  padding: 0;
  cursor: pointer;
  vertical-align: top;
}
.slick-dots li button
{
  font-size: 0;
  line-height: 0;
  display: block;

  width: 15px;
  height: 15px;
  padding: 0;

  cursor: pointer;

  color: transparent;
  border: none;
  border-radius: 5px;
  outline: none;
  background: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: .5;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  opacity: 1;
}
.slick-dots li.slick-active button
{
  opacity: 1;
  color: black;
  @if $enable-shadows {
    @include box-shadow($btn-box-shadow, 0 0px 10px 2px rgba($primary, .5));
  }
}
