#sidebar {
  flex: 1 auto;
  min-width: 270px;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .logo img {
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-only(md) {
    top: 90px !important;
    height: calc(100% - 90px) !important;
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    z-index: 100;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: all .4s;

    > div {
      padding-top: 70px !important;
      height: 100%;
      overflow: scroll;
    }

    .card {
      width: 250px;
      margin: 0 auto;
    }

    &.is-open {
      left: 0;
    }
  }
}

#sidebar-nav {
  .btn {
    display: block;
    width: 160px;
    text-align: left;
    margin: 0 auto 1.5rem;
  }
}
