#nft-bees-wrapper {
  min-height: 490px;
  overflow-x: auto;

  > div {
    width: 2190px;

    .card {
      width: 300px;
      margin-right: 15px;
      display: inline-flex;

      &:last-child {
        margin-right: 0;
      }

      .input-group {
        max-width: 150px;
      }
    }
  }
}
