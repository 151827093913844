#storage-bees-wrapper {
  min-height: 300px;
  overflow-x: auto;

  > div {
    width: 2190px;

    .slider-item {
      width: 300px;
      margin-right: 15px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      .input-group {
        max-width: 150px;
      }
    }
  }
}


.remove-item {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  width: 25px;
  height: 25px;
  background: $danger;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.game-card {
  position: relative;
  width: 200px;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  cursor: pointer;
  transform: scale(0.95);
  transition: all .2s ease;

  &:hover {
    transform: scale(1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  }

  .game-card-set {
    text-align: center;
    position: absolute;
    top: 8px;
    left: 10px;
    width: 180px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .game-card-title {
    position: absolute;
    top: 29px;
    left: 10px;
    width: 180px;
    text-align: center;
  }

  .game-card-image {
    position: absolute;
    top: 55px;
    left: 20px;
    width: 160px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: auto;
      max-width: 60%;
      max-height: 60%;
      margin: auto auto;
    }
  }

  .game-card-bee {
    position: absolute;
    top: 150px;
    left: 10px;
    width: 180px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;

    span {
      border-radius: 20px;
      padding: 1px 5px;
      margin-right: 5px;
      text-transform: uppercase;
    }
  }

  .game-card-profit {
    position: absolute;
    top: 175px;
    left: 10px;
    width: 180px;
    text-align: center;

    .game-card-profit-title {
      display: block;
      letter-spacing: 2px;
    }
    .game-card-profit-value {
      color: #fff;
      font-size: 45px;
      text-shadow: 0 0 4px black, 0 5px 5px rgba(0, 0, 0, 0.8);
    }
  }

  .game-card-amount {
    position: absolute;
    font-size: 9px;
    bottom: 5px;
    left: 10px;
    width: 180px;
    text-align: center;

    span {
      display: inline-block;
      border-radius: 10px;
      padding: 1px 5px;
    }
  }
}

.game-card.game-card-gray {
  $main-color: $white;
  $secondary-color: #34393D;
  color: $main-color;

  .game-card-set {
    color: $main-color;
  }
  .game-card-title {
    color: $secondary-color;
  }
  .game-card-amount span,
  .game-card-bee span {
    background: $secondary-color;
    color: $main-color;
  }
}

.game-card.game-card-white {
  $main-color: #494F5F;
  $secondary-color: $black;
  color: $main-color;

  .game-card-set {
    color: $white;
  }
  .game-card-title {
    color: $secondary-color;
  }
  .game-card-amount span,
  .game-card-bee span {
    background: $secondary-color;
    color: $white;
  }
}

.game-card.game-card-blue {
  $main-color: $white;
  $secondary-color: #012861;
  color: $main-color;

  .game-card-set {
    color: $main-color;
  }
  .game-card-title {
    color: $secondary-color;
  }
  .game-card-amount span,
  .game-card-bee span {
    background: $secondary-color;
    color: $main-color;
  }
}

.game-card.game-card-purple {
  $main-color: #FF8C00;
  $secondary-color: #501C61;
  color: $main-color;

  .game-card-set {
    color: $main-color;
  }
  .game-card-title {
    color: $secondary-color;
  }
  .game-card-amount span,
  .game-card-bee span {
    background: $secondary-color;
    color: $main-color;
  }
}
